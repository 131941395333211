<template>
    <div>
      <v-row>

        <v-col cols="12">
          <div class="float-right">
            <v-btn color="primary" class="white--text" @click="reactivatePersons()" :disabled="(selected.length == 0)">Wiederherstellen</v-btn>
            <v-btn color="secondary" outlined @click="deletePersons()" class="ml-2" :disabled="(selected.length == 0)">Entgültig Löschen</v-btn>
          </div>
          <h2>Personen</h2>
          <v-data-table :headers="headers"
                        :items="filteredPapierkorb"
                        class="elevation-1 mt-2"
                        selectable
                        multi-sort
                        v-model="selected"
                        item-key="id"
                        show-select
                        color="accent">
          </v-data-table>
        </v-col>
        <v-col cols="12">
          <div class="float-right">
            <v-btn color="primary" class="white--text" @click="reactiveFirma" :disabled="(selectedFirmen.length === 0)">Wiederherstellen</v-btn>
            <v-btn color="secondary" outlined class="ml-2" @click="deleteFirmen" :disabled="(selectedFirmen.length === 0)">Entgültig Löschen</v-btn>
          </div>
          <h2>Firmen</h2>
          <v-data-table :headers="firmenHeaders"
                        :items="firmenPapierkorb"
                        class="elevation-1 mt-2"
                        selectable
                        multi-sort
                        v-model="selectedFirmen"
                        item-key="id"
                        show-select
                        color="accent">
          </v-data-table>
        </v-col>
      </v-row>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        data() {
            return {
                selected: [],
                person: null,
                aktionsteilnahmen: [],
                selectedFirmen:[],
                headers: [
                    { text: 'Anrede', value: 'anrede', sortable: true },
                    { text: 'Titel', value: 'titel', sortable: true },
                    { text: 'Vorname', value: 'vorname', sortable: true },
                    { text: 'Nachname', value: 'nachname', sortable: true },
                    { text: 'Email', value: 'email', sortable: true },
                    { text: 'Telefon', value: 'telefon', sortable: true },
                    { text: 'Firma', value: 'firma', sortable: true },
                    { text: 'Ort', value: 'ort', sortable: true },
                    { text: 'Kontakt Von', value: 'kontaktVon', sortable: true }],
              firmenHeaders: [
                { text: 'Name', value: 'name' },
                { text: 'Straße', value: 'straße' },
                { text: 'Ort', value: 'ort' },
                { text: 'PLZ', value: 'plz' },
                { text: 'Land', value: 'land.name' },
                { text: 'Telefon', value: 'telefon' },],

                anrede: ['Herr', 'Frau', 'K/A'],
            }
        },
        watch: {

        },
        computed: {
            ...mapState(['profile', 'personen', 'papierkorb', 'filteredPapierkorb', 'firmen', 'praesente', 'firmenPapierkorb']),

            computedDateFormatted() {
                return this.formatDate(this.person.geburtstag)
            }
        },
        created() {
            this.$store.dispatch('getDeletedPersonen');
            this.$store.dispatch('getDeletedFirmen');
        },
        beforeMount() {

        },
        mounted() {

        },
        props: ['laender'],
        methods: {
          toggleAll () {
            if (this.selected.length) this.selected = []
            else this.selected = this.desserts.slice()
          },
            formatDate(date) {
                if (!date) return null

                var date = new Date(date);

                var day = date.getDate().toString();
                day = day.length == 1 ? "0" + day : day;

                var month = (date.getMonth() + 1).toString();
                month = month.length == 1 ? "0" + month : month;

                var year = date.getFullYear().toString();
                return day + '.' + month + '.' + year;
            },
          async reactiveFirma(){
            await this.$http.post('/api/stammdaten/ReactiveFirma', this.selectedFirmen);
            await this.$store.dispatch('deleteFirmaPapierkorb',  this.selectedFirmen);
            await this.$store.dispatch('addFirmen',  this.selectedFirmen);
          },

          async deleteFirmen(){
            const selectedIds = this.selectedFirmen.map(sf => sf.id);
            await this.$http.post('/api/stammdaten/DeleteFirmenFinally', selectedIds);
            await this.$store.dispatch('deleteFirmaPapierkorb',  this.selectedFirmen);
            await this.$store.dispatch('getPersonen');
            this.selectedFirmen = [];
          },

          expand(value){
            const item = this.expanded.find(i => i.id === value.id);
            const index = this.expanded.indexOf(item);

            if(index > -1){
              this.expanded.splice(index, 1);
            }
            else{
              this.expanded = [];
              this.expanded.push(value);
            }
          },

            callPerson: function (person) {
                this.$http.get('/api/Stammdaten/GetPerson/' + person.id).then(response => {
                    this.person = response.data;
                    if (this.person.land == null) {
                        this.person.land = { id: 0 };
                    }

                    if (this.person.geburtstag != null) {
                        var bday = this.person.geburtstag;
                        this.person.geburtstag = bday.substr(0, 10);
                    }
                }).catch((error) => console.log(error))

                this.setAktionen(person.id);
            },

            setAktionen: function (id) {
                this.$http.get('/api/Stammdaten/AktionsNamen/' + id).then(response => {
                    this.aktionsteilnahmen = response.data;
                }).catch((error) => console.log(error));
            },

            reactivatePersons: function () {
                this.$http.post('/api/Stammdaten/ReactivtePersons', this.selected).then(response => {
                  this.$store.dispatch('deletePersonenPapierkorb', this.selected);
                  this.$store.dispatch('addPersonen', this.selected);
                    this.selected = [];
                }).catch((error) => console.log(error));
            },

            deletePersons: function () {
                this.$http.post('/api/Stammdaten/DeletePersonsFinally', this.selected).then(response => {
                  this.$store.dispatch('deletePersonenPapierkorb', this.selected);
                    this.selected = [];
                }).catch((error) => console.log(error));
            },
        },

    }
</script>

<style scoped>
</style>
