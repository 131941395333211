import { render, staticRenderFns } from "./papierkorb.vue?vue&type=template&id=778d92b5&scoped=true&"
import script from "./papierkorb.vue?vue&type=script&lang=js&"
export * from "./papierkorb.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "778d92b5",
  null
  
)

export default component.exports